import React from 'react';

/**
 * Stars background
 * @returns
 */
function Stars() {
	return (
		<>
			<div id="stars" className=""></div>
			<div id="stars2" className=""></div>
			<div id="stars3" className=""></div>
		</>
	);
}

export default Stars;
