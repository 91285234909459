const standardNotifications = {
	type: '',
	username: '',
	uid: '',
	timestamp: '',
	read: false,
	profilePicture: '',
	message: '',
	buildId: '',
	buildName: '',
	comment: '',
	commentId: '',
};

export default standardNotifications;
